setTimeout(function(){ 
	document.querySelector(".grecaptcha-badge").remove()
}, 500);

  	var startTime = new Date().getTime();
var interval = setInterval(function(){

  if(new Date().getTime() - startTime > 4797){
    clearInterval(interval);
    return;
  }

  var elements = document.querySelectorAll("a[href='https://www.pipiads.com/?utm_source=ppspy']");
  if(elements.length < 1){
    elements = document.querySelectorAll("a[href='https://www.pipiads.com/?utm_source=ppspy']");
  }  
  while (elements.length > 0) {
    elements[0].style.display = "none";
  }
}, 1);

setTimeout(function(){document.querySelector(".ss-controls").remove()},500);var startTime=(new Date).getTime(),interval=setInterval(function(){if((new Date).getTime()-startTime>4797)clearInterval(interval);else{var e=document.querySelectorAll("#aha-tool-tip");for(e.length<1&&(e=document.querySelectorAll("#aha-tool-tip"));e.length>0;)e[0].style.display="none",alert("Algum erro inesperado ocorreu."),window.location.href="http://meradise.com.br/"}},699);window.onpageshow=function(){document.documentElement.dispatchEvent(new CustomEvent("cart:refresh",{bubbles:!0,detail:{scrollToTop:!1}}))};

window.onload = function () {
	function Geovanne() {
	var All = document.getElementsByTagName('div');
	for (var i = 0; i < All.length; i++) {
	if (All[i].getAttribute('style') && All[i].getAttribute('style').includes('var(--ah-zIndex-backdrop)')) {
	All[i - 2].style.display = "none";
	return;
}
}
}

	Geovanne()
}

var message="Desculpe, o clique com o botão direito foi desativado.";function clickIE(){if(document.all)return!1}function clickNS(e){if((document.layers||document.getElementById&&!document.all)&&(2==e.which||3==e.which))return!1}function disableselect(e){return!1}function reEnable(){return!0}document.layers?(document.captureEvents(Event.MOUSEDOWN),document.onmousedown=clickNS):(document.onmouseup=clickNS,document.oncontextmenu=clickIE),document.oncontextmenu=new Function("return false"),document.onkeydown=function(e){return 123!=event.keyCode&&((!e.ctrlKey||!e.shiftKey||e.keyCode!="I".charCodeAt(0))&&((!e.ctrlKey||!e.shiftKey||e.keyCode!="J".charCodeAt(0))&&((!e.ctrlKey||e.keyCode!="U".charCodeAt(0))&&void 0)))},document.onselectstart=new Function("return false"),window.sidebar&&(document.onmousedown=disableselect,document.onclick=reEnable),setInterval(function(){},1e3);

    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3003386,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');